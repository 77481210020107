import {
  Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography,
} from '@mui/material';
import React from 'react';

export default function Experience() {
  return (
    <>
      <Typography variant="h5" className="header" sx={{ mb: 2 }}>
        Experience
      </Typography>

      <Typography variant="body2" className="header" component="div">
        <List>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                src="https://media-exp1.licdn.com/dms/image/C560BAQGiWITCRsaaDQ/company-logo_100_100/0/1538734886365?e=1647475200&v=beta&t=Cx4Uk0hIT_Bh2Zru0fatSwlQjSetP-4jGsgpf0VQ38U"
              />
            </ListItemAvatar>
            <ListItemText
              primary={(
                <Typography variant="h5">
                  Software Engineer/DevOps Engineer
                  (consultant)
                </Typography>
                            )}
              secondaryTypographyProps={{ sx: { color: '#fff' } }}
              secondary={(
                <>
                  <Typography variant="subtitle2">
                    Global Airport Concierge (Nov 2020 - Present)
                  </Typography>
                  <Typography variant="subtitle1">
                    <ul>
                      <li>Design and develop frontend and backend components.</li>
                      <li>
                        Implement well-designed, testable, efficient code by using the best
                        software development practices.
                      </li>
                      <li>Building and setting up new development tools and infrastructure</li>
                      <li>
                        {' '}
                        Working on ways to automate and improve development and release
                        processes
                      </li>
                      <li>
                        Ensuring that systems are safe and secure against cyber security
                        threats
                      </li>
                      <li>
                        Clearly and regularly communicate with management and technical support
                        colleagues.
                      </li>
                      <li>Maintains all the cloud servers.</li>
                    </ul>
                  </Typography>
                </>
                            )}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                sx={{ backgroundColor: '#fff' }}
                src="https://lh6.googleusercontent.com/-UNmYrKOybxs/AAAAAAAAAAI/AAAAAAAAAAA/pJiVZpzGPWg/s44-p-k-no-ns-nd/photo.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary={(<Typography variant="h5">Senior Software Engineer</Typography>)}
              secondaryTypographyProps={{ sx: { color: '#fff' } }}
              secondary={(
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="subtitle2"
                  >
                    99X Technology (Apr 2020 - May 2021)
                  </Typography>
                  <Typography variant="subtitle1">
                    <ul>
                      <li>Design and develop frontend components.</li>
                      <li>
                        Implement well-designed, testable, efficient code by
                        using best software development practices.
                      </li>
                      <li>
                        Optimization of the application for maximum speed and scalability.
                      </li>
                      <li>Design and implementation of data storage solutions.</li>
                      <li>Integrate data from various back-end services and databases.</li>
                      <li>Create and maintain software technical documentation.</li>
                    </ul>
                  </Typography>
                </>
                            )}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                src="https://lh6.googleusercontent.com/-Ge6oMLDw0W8/AAAAAAAAAAI/AAAAAAAAAAA/A8OB776MSuk/s44-p-k-no-ns-nd/photo.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary={(<Typography variant="h5">Senior Software Engineer</Typography>)}
              secondaryTypographyProps={{ sx: { color: '#fff' } }}
              secondary={(
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="subtitle2"
                  >
                    SolomoIT (Aug 2018 - Apr 2020)
                  </Typography>
                  <Typography variant="subtitle1">
                    <ul>
                      <li>
                        Integration of user-facing elements developed by front-end developers
                        with server-side logic.
                      </li>
                      <li>
                        Work closely with Tech Lead, project managers, UX designers, other
                        developers, systems analysts, and sales and marketing professionals.
                      </li>
                      <li>
                        Implement well-designed, testable, efficient code by using the best
                        software development practices.
                      </li>
                      <li>Building reusable code and libraries for future use.</li>
                      <li>Mentoring other developers and handling KT sessions.</li>
                      <li>Optimization of the application for maximum speed and scalability.</li>
                      <li>Implementation of security and data protection.</li>
                    </ul>
                  </Typography>
                </>
                            )}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                src="https://lh4.googleusercontent.com/-gVr9U3Y0dh0/AAAAAAAAAAI/AAAAAAAAAAA/p-sEvJa-vto/s44-p-k-no-ns-nd/photo.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary={(<Typography variant="h5">Tech Lead</Typography>)}
              secondaryTypographyProps={{ sx: { color: '#fff' } }}
              secondary={(
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="subtitle2"
                  >
                    Apple Holidays (Aug 2016 - Aug 2018)
                  </Typography>
                  <Typography variant="subtitle1">
                    <ul>
                      <li>Design, develop, test, and implement new applications.</li>
                      <li>
                        Train interns.
                      </li>
                      <li>
                        Clearly and regularly communicate with management and technical support
                        colleagues.
                      </li>
                      <li>
                        Design and update software database.
                      </li>
                      <li>
                        Analyze user requirements and convert requirements to design
                        documents.
                      </li>
                    </ul>
                  </Typography>
                </>

                            )}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                src="https://lh4.googleusercontent.com/-gVr9U3Y0dh0/AAAAAAAAAAI/AAAAAAAAAAA/p-sEvJa-vto/s44-p-k-no-ns-nd/photo.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary={(<Typography variant="h5">Full-stack Developer</Typography>)}
              secondaryTypographyProps={{ sx: { color: '#fff' } }}
              secondary={(
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="subtitle2"
                  >
                    Apple Holidays (Oct 2014 - Aug 2015)
                  </Typography>
                  <Typography variant="subtitle1">
                    <ul>
                      <li>
                        Develop, test, and implement new applications.
                      </li>
                      <li>
                        Customizing systems/applications made by the company.
                      </li>
                      <li>Design and update software database.</li>
                      <li>Implementation of security and data protection.</li>
                      <li>Design and implementation of data storage solutions.</li>
                      <li>Integrate data from various back-end services and databases.</li>
                      <li>
                        Worked closely in the planning, design, and delivery of software
                        products and services.
                      </li>
                    </ul>
                  </Typography>
                </>
                            )}
            />
          </ListItem>
        </List>
      </Typography>
    </>
  );
}
