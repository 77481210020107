import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Grow,
  IconButton,
  Link,
  Slide,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  Suspense, useEffect, useRef, useState,
} from 'react';
import '../../style/home.component.scss';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Howl } from 'howler';
import BiotechIcon from '@mui/icons-material/Biotech';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ArticleIcon from '@mui/icons-material/Article';
import WorkIcon from '@mui/icons-material/Work';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CloseIcon from '@mui/icons-material/Close';
import Skills from './skills';
import Experience from './experience';
import Projects from './projects';
import Contacts from './contacts';

const useStyles = makeStyles((theme) => ({
  card: {
    color: 'white !important',
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.50) !important',
    borderRadius: '10px',
  },
  content: {
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      maxWidth: '50rem',
      maxHeight: 'calc(100% - 2.5rem)',
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
    borderRadius: '30px',
    width: '100%',
    color: 'white !important',
    backgroundColor: 'rgba(0,0,0,0.9) !important',
    overflowY: 'auto !important' as any,
  },
  gameStartButton: {
    backgroundColor: '#e91e63 !important',
  },
  gameCloseButton: {
    backgroundColor: '#607d8b !important',
  },
  closeButton: {
    position: 'absolute !important' as any,
    width: '96%',
  },
}));

const Balls = React.lazy(() => import('./balls'));

export default function HomeComponent() {
  const classes = useStyles();
  const ballGame: any = useRef(null);
  const [showGameBoard, setShowGameBoard] = useState(false);

  const [won, setWon]: any = useState(false);
  const [techsFounded, setTechFounded]: any = useState([]);

  const [contentType, setContentType]: any = useState('experience');
  const [contentOpened, setContentOpened]: any = useState(false);

  const techs = [
    { key: 'docker', label: 'Docker' },
    { key: 'php', label: 'PHP' },
    { key: 'react', label: 'ReactJs' },
    { key: 'javascript', label: 'JavaScript' },
    { key: 'nodejs', label: 'Node.js' },
    { key: 'typescript', label: 'TypeScript' },
    { key: 'nextjs', label: 'Next.js' },
    { key: 'nestjs', label: 'NestJs' },
    { key: 'vuejs', label: 'Vue.js' },
    { key: 'angular', label: 'Angular' },
    { key: 'ubuntu', label: 'Ubuntu' },
    { key: 'git', label: 'Git' },
    { key: 'koa', label: 'Koa.js' },
    { key: 'symfony', label: 'Symfony' },
    { key: 'aws', label: 'AWS' },
    { key: 'gitlab', label: 'Gitlab' },
    { key: 'kubernetes', label: 'Kubernetes' },
    { key: 'mongodb', label: 'MongoDB' },
    { key: 'gc', label: 'Google Cloud' },
    { key: 'microservices', label: 'Microservices' },
  ];

  const handleGame = () => {
    if (ballGame.current) {
      setTechFounded([]);
      setContentOpened(false);
      ballGame?.current.startAnimateGame(() => {
        setShowGameBoard(true);
        // eslint-disable-next-line no-new
        new Howl({
          src: ['/audio/notification.mp3'],
          autoplay: true,
        });
      });
    }
  };

  const closeGame = () => {
    setTechFounded([]);
    setShowGameBoard(false);
    ballGame?.current.closeAnimateGame(() => {
    });
  };

  const handleOnFound = (techItem: string) => {
    setTechFounded((currentTechs: any[]) => [...currentTechs, techItem]);
  };

  const handleContent = (type: string) => {
    setContentOpened(true);
    setContentType(type);
    // eslint-disable-next-line no-new
    new Howl({
      src: ['/audio/pop.mp3'],
      autoplay: true,
    });
  };

  useEffect(() => {
    if (((techsFounded.length / techs.length) * 100) >= 80 && !won) {
      // eslint-disable-next-line no-new
      new Howl({
        src: ['/audio/victory.mp3'],
        autoplay: true,
      });
      setWon(false);
    }
  }, [techsFounded]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
    }, 2000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <>
      <Suspense fallback={<span />}>
        <Balls techs={techs} ref={ballGame} onFound={handleOnFound} />
      </Suspense>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>

          <Box mb={5} mt="10%" ml="5%" className="summery-home text-left">
            <Typography variant="h1" className="header">Hi, my name is Supun. </Typography>
            <Box mb={1} />
            <Typography variant="h3">I am a Full-stack Developer.</Typography>
            <Box mb={10} />

            <Stack direction="row" spacing={2}>
              <Grow in timeout={1000}>
                <Tooltip
                  title={<Typography variant="h5">Skills and Technologies</Typography>}
                  arrow
                  placement="bottom"
                >
                  <IconButton
                    color="success"
                    className="filled-button"
                    onClick={() => handleContent('tech')}
                  >
                    <BiotechIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Grow>

              <Grow in timeout={1000}>
                <Tooltip
                  title={<Typography variant="h5">Projects</Typography>}
                  arrow
                  placement="bottom"
                >
                  <IconButton
                    onClick={() => handleContent('projects')}
                    color="success"
                    className="filled-button"
                  >
                    <FactCheckIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Grow>

              <Grow in timeout={1000}>
                <Tooltip
                  title={<Typography variant="h5">Experience</Typography>}
                  arrow
                  placement="bottom"
                >
                  <IconButton
                    color="success"
                    className="filled-button"
                    onClick={() => handleContent('experience')}
                  >
                    <WorkIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Grow>

              <Grow in timeout={1000}>
                <Tooltip title={<Typography variant="h5">Contact</Typography>} arrow placement="bottom">
                  <IconButton
                    color="success"
                    className="filled-button"
                    onClick={() => handleContent('contacts')}
                  >
                    <ContactMailIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Grow>
              <Grow in timeout={1000}>
                <Tooltip title={<Typography variant="h5">Resume</Typography>} arrow placement="bottom">
                  <Link href="/Resume_Supun_Praneeth.pdf" target="_blank">
                    <IconButton color="success" className="filled-button">
                      <ArticleIcon fontSize="large" />
                    </IconButton>
                  </Link>
                </Tooltip>
              </Grow>

              {showGameBoard ? (
                <>
                  <IconButton
                    onClick={handleGame}
                    color="success"
                    className={`filled-button ${classes.gameStartButton}`}
                  >
                    <Tooltip
                      title={<Typography variant="h5">Refresh the Game</Typography>}
                      arrow
                      placement="bottom"
                    >
                      <RefreshIcon fontSize="large" />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={closeGame}
                    color="success"
                    className={`filled-button ${classes.gameCloseButton}`}
                  >
                    <Tooltip
                      title={<Typography variant="h5">Close the Game</Typography>}
                      arrow
                      placement="bottom"
                    >
                      <CloseIcon fontSize="large" />
                    </Tooltip>
                  </IconButton>
                </>
              ) : (
                <IconButton
                  onClick={handleGame}
                  color="success"
                  className={`filled-button ${classes.gameStartButton}`}
                >
                  <Tooltip
                    title={<Typography variant="h5">Play Catch my Skills</Typography>}
                    arrow
                    placement="bottom"
                  >
                    <PlayArrowIcon fontSize="large" />
                  </Tooltip>
                </IconButton>
              )}

            </Stack>
          </Box>

        </Grid>
        <Grid item xs={12} md={5}>

          <Box mt={5} mr={5}>
            <Slide
              direction="left"
              timeout={500}
              in={contentOpened}
              mountOnEnter
              unmountOnExit
            >
              <Card className={classes.content}>
                <CardContent>
                  <Typography
                    component="div"
                    textAlign="right"
                    className={classes.closeButton}
                  >
                    <IconButton onClick={() => setContentOpened(false)}>
                      <CloseIcon color="info" fontSize="small" />
                    </IconButton>

                  </Typography>

                  {contentType === 'tech' && <Skills />}
                  {contentType === 'experience' && <Experience />}
                  {contentType === 'projects' && <Projects />}
                  {contentType === 'contacts' && <Contacts />}
                </CardContent>
              </Card>
            </Slide>
          </Box>

          <Box mt={5} mr={5}>
            <Slide direction="left" timeout={500} in={showGameBoard} mountOnEnter unmountOnExit>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h4" className="header">
                    Capture at least 80% of my skills
                  </Typography>
                  <Box mb={4} />
                  <Typography variant="body1" className="header">
                    You have captured
                    {' '}
                    {Math.round((techsFounded.length / techs.length) * 100)}
                    % of skills
                    (
                    {techs.length - techsFounded.length}
                    {' '}
                    skills are remaining
                    )
                  </Typography>

                  <Box sx={{ borderStyle: 'dashed' }} minHeight={100} mt={3} p={1}>
                    {techsFounded.map((tech: any, index: number) => (
                      <Box key={index} p={0.5} display="inline-block">
                        <Chip
                          variant="filled"
                          size="small"
                          color="info"
                          icon={(
                            <Avatar
                              src={`/tech/${tech}.png`}
                              style={{ height: 'inherit', width: 'inherit' }}
                            />
                                                    )}
                          label={techs.find((techItem: any) => tech === techItem.key)?.label}
                        />
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Slide>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} />
        <Grid item xs={12} md={3} />
      </Grid>
    </>
  );
}
