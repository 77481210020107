import {
  Avatar, Link, List, ListItem, ListItemAvatar, ListItemText, Typography,
} from '@mui/material';
import React from 'react';

export default function Contacts() {
  return (
    <>
      <Typography variant="h5" className="header" sx={{ mb: 2 }}>
        Contacts
      </Typography>

      <Typography variant="body2" className="header" component="div">
        <List>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Link href="https://www.linkedin.com/in/spmsupun/" target="_blank">
                <Avatar
                  src="/linkedin.png"
                />
              </Link>
            </ListItemAvatar>
            <ListItemText
              primary={(
                <Typography variant="h5">
                  LinkedIn
                </Typography>
                            )}
              secondaryTypographyProps={{ sx: { color: '#fff' } }}
              secondary={(
                <Typography variant="subtitle2">
                  spmsupun
                </Typography>
                            )}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Link href="mailto:contact@supun.xyz" target="_blank">
                <Avatar
                  sx={{ backgroundColor: '#fff' }}
                  src="/email.png"
                />
              </Link>
            </ListItemAvatar>
            <ListItemText
              primary={(
                <Typography variant="h5">
                  Email
                </Typography>
                            )}
              secondaryTypographyProps={{ sx: { color: '#fff' } }}
              secondary={(
                <Typography variant="subtitle2">
                  contact@supun.***
                </Typography>
                            )}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Link href="https://stackoverflow.com/users/801448/supun-praneeth" target="_blank">
                <Avatar
                  sx={{
                    backgroundColor: '#2aa7ff', p: 1.5, height: '2rem', width: '2rem',
                  }}
                  src="/stack-overflow.png"
                />
              </Link>
            </ListItemAvatar>
            <ListItemText
              primary={(
                <Typography variant="h5">
                  Stackoverow
                </Typography>
                            )}
              secondaryTypographyProps={{ sx: { color: '#fff' } }}
              secondary={(
                <Typography variant="subtitle2">
                  stackoverflow.com/users/801448/supun-praneeth
                </Typography>
                            )}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Link href="https://github.com/spmsupun" target="_blank">
                <Avatar sx={{ backgroundColor: '#fff' }} src="/tech/github.png" />
              </Link>
            </ListItemAvatar>
            <ListItemText
              primary="Github"
              primaryTypographyProps={{ sx: { color: '#fff' } }}
              secondary={(
                <Typography
                  sx={{ display: 'inline', color: '#fff' }}
                  component="span"
                  variant="body2"
                >
                  spmsupun
                </Typography>
                        )}
            />
          </ListItem>
        </List>
      </Typography>
    </>
  );
}
