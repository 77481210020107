import { createTheme } from '@mui/material';
import variants from './variants';
import typography from './typography';
import breakpoints from './breakpoints';
import props from './props';
import shadows from './shadows';

const theme = (variant) => createTheme(
  {
    spacing: 4,
    breakpoints,
    overrides: variant.overrides,
    props,
    typography,
    shadows,
    body: variant.body,
    highlight: variant.highlight,
    header: variant.header,
    palette: variant.palette,
    sidebar: variant.sidebar,
    standard: variant.standard,
  },
  variant.name,
);
export default theme(variants);
