import React from 'react';
import './App.scss';
import { ThemeProvider } from '@mui/material';
import HomeComponent from './component/home/home.component';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HomeComponent />
    </ThemeProvider>
  );
}

export default App;
