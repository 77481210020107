const typography = {
  useNextVariants: true,
  fontFamily: 'fiction-light, -apple-system, BlinkMacSystemFont,  Calibri, "Segoe UI", "Open Sans", Ubuntu,"Helvetica Neue", sans-serif',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  h1: {
    fontSize: '2rem',
    lineHeight: 1.2,
  },
  h2: {
    fontSize: '1.75rem',
    lineHeight: 1.2,
  },
  h3: {
    fontSize: '1.5rem',
    lineHeight: 1.2,
  },
  h4: {
    fontSize: '1.25rem',
    lineHeight: 1.2,
  },
  h5: {
    fontSize: '1.125rem',
    lineHeight: 1.2,
  },
  h6: {
    fontSize: '1.0625rem',
    lineHeight: 1.2,
  },
  body1: {
    fontSize: 14,
  },
  button: {
    textTransform: 'none',
  },
};

export default typography;
