import {
  Avatar, Divider, Link, List, ListItem, ListItemAvatar, ListItemText, Typography,
} from '@mui/material';
import React from 'react';

export default function Projects() {
  return (
    <>
      <Typography variant="h5" className="header" sx={{ mb: 2 }}>
        Top Projects
      </Typography>

      <Typography variant="body2" className="header" component="div">
        <List>
          <Link href="https://lifeeka.com" target="_blank">
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar src="https://avatars.githubusercontent.com/u/11577594?s=400&u=f85233217f6608475adc7394eb54681b0a9e8b49&v=4" />
              </ListItemAvatar>
              <ListItemText
                primary="Lifeeka"
                primaryTypographyProps={{ sx: { color: '#fff' } }}
                secondary={(
                  <Typography
                    sx={{ display: 'inline', color: '#fff' }}
                    component="span"
                    variant="body2"
                  >
                    Social network website
                  </Typography>
                            )}
              />
            </ListItem>
          </Link>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar src="https://media-exp1.licdn.com/dms/image/C560BAQGiWITCRsaaDQ/company-logo_100_100/0/1538734886365?e=1647475200&v=beta&t=Cx4Uk0hIT_Bh2Zru0fatSwlQjSetP-4jGsgpf0VQ38U" />
            </ListItemAvatar>
            <ListItemText
              primary="Global Airport Concierge "
              secondary={(
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                >
                  Airport service booking platform/API
                </Typography>
                            )}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar src="https://lh4.googleusercontent.com/-gVr9U3Y0dh0/AAAAAAAAAAI/AAAAAAAAAAA/p-sEvJa-vto/s44-p-k-no-ns-nd/photo.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Apple Holidays Booking Creator"
              secondary={(
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                >
                  Complete booking platform with Hotels, Transport and Flights
                </Typography>
                            )}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <Link href="https://github.com/spmsupun" target="_blank">
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar sx={{ backgroundColor: '#fff' }} src="/tech/github.png" />
              </ListItemAvatar>
              <ListItemText
                primary="Github"
                primaryTypographyProps={{ sx: { color: '#fff' } }}
                secondary={(
                  <Typography
                    sx={{ display: 'inline', color: '#fff' }}
                    component="span"
                    variant="body2"
                  >
                    Check out my Open source projects
                  </Typography>
                            )}
              />
            </ListItem>
          </Link>
        </List>
      </Typography>
    </>
  );
}
