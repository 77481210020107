import { blue, green, grey } from '@material-ui/core/colors';

const darkVariant = () => ({
  name: 'Dark',
  standard: {
    color: '#70c5bc',
    white: '#fff',
    error: '#ff4747',
  },
  palette: {
    type: 'dark',
    background: {
      default: '#36393f',
    },
    primary: {
      main: '#202225',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#202225',
      contrastText: '#FFF',
    },
  },
  highlight: {
    background: '#2C3C52',
    color: '#fff',
  },
  header: {
    color: grey[500],
    background: '#202225',
    search: {
      color: grey[800],
    },
    indicator: {
      background: blue[600],
    },
  },
  sidebar: {
    color: grey[200],
    background: '#2f3136',
    header: {
      color: grey[200],
      background: '#2f3136',
      brand: {
        color: blue[500],
      },
    },
    footer: {
      color: grey[200],
      background: '#232f3e',
      online: {
        background: green[500],
      },
    },
    category: {
      fontWeight: 400,
    },
    badge: {
      color: '#FFF',
      background: blue[500],
    },
  },
  body: {
    background: '#F7F9FC',
  },
  overrides: {
  },
});

export default darkVariant;
