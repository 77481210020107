import {
  Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography,
} from '@mui/material';
import React from 'react';

export default function Skills() {
  return (
    <>
      <Typography variant="h5" className="header" sx={{ mb: 2 }}>
        Skills/Technologies
      </Typography>

      <Typography variant="body2" className="header" component="div">
        <List>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar src="/tech/php.png" />
            </ListItemAvatar>
            <ListItemText
              primary="PHP"
              secondary={(
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                >
                  +5 years with Laravel framework
                </Typography>
                            )}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar src="/tech/javascript.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Javascript"
              secondary={(
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                >
                  ReactJs, ES6, NestJs, Typescript
                </Typography>
                            )}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar src="/tech/sass.png" />
            </ListItemAvatar>
            <ListItemText
              primary="CSS/SCSS"
              secondary={(
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                >
                  Pure CSS/SCSS and frameworks like Material-UI and Bootstrap
                </Typography>
                            )}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar src="/tech/docker.png" />
            </ListItemAvatar>
            <ListItemText
              primary="Docker"
              secondary={(
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                >
                  Docker, Docker Compose and Docker Swarm
                </Typography>
                            )}
            />
          </ListItem>
        </List>
        <Typography variant="subtitle2">Worked with</Typography>
        <Typography variant="body1">
          Next.js, NestJs, Vue.js, Angular,
          Linux, Git, Koa.js, Symfony 4,
          AWS, Gitlab CI/CD, Github
          Actions
        </Typography>

        <Typography variant="subtitle2" sx={{ mt: 2 }}>Familiar With</Typography>
        <Typography variant="body1">
          Kubernetes, MongoDB, Google
          Cloud, Microservices, TDD,
          SOLID Principles
        </Typography>
      </Typography>
    </>
  );
}
